import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, ListItem, ListItemText,
  IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, FormControl, InputLabel, Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import { useAbility } from '../context/AbilityContext';
import { Can } from '@casl/react';  // Import Can from CASL

const Teacher = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({ name: '', trainingLevel: '' });
    const [trainingLevels, setTrainingLevels] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [openDialog, setOpenDialog] = useState(false);
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
        fetchTrainingLevels();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get('/teachers');
            const sortedData = sortItems(response.data.data, sortOrder); // Access response.data.data
            setItems(Array.isArray(sortedData) ? sortedData : []);
        } catch (error) {
            console.error('Error fetching teachers:', error);
        }
    };

    const fetchTrainingLevels = async () => {
        try {
            const response = await api.get('/traininglevels');  // Ensure endpoint name is correct
            const sortedLevels = response.data.data // Adjust data access
                .filter(level => level.name)
                .sort((a, b) => a.name.localeCompare(b.name));
            setTrainingLevels(sortedLevels);
        } catch (error) {
            console.error('Error fetching training levels:', error);
        }
    };

    const createOrUpdateItem = async (e) => {
        e.preventDefault();

        if (!form.trainingLevel) {
            return; // Prevent submission if no training level is selected
        }

        if (editMode) {
            try {
                const response = await api.put(`/teachers/${currentId}`, form);
                const updatedItems = items.map(item =>
                    item._id === currentId ? response.data.data : item // Ensure you're accessing the correct data format
                );
                setItems(sortItems(updatedItems, sortOrder));
                resetForm();
            } catch (error) {
                console.error('Error updating teacher:', error);
            }
        } else {
            const conflict = checkForConflict(form.name);
            if (conflict) {
                setOpenDialog(true);
            } else {
                try {
                    const response = await api.post('/teachers', form);
                    const updatedItems = [...items, response.data.data]; // Adjust data access
                    setItems(sortItems(updatedItems, sortOrder));
                    resetForm();
                } catch (error) {
                    console.error('Error creating teacher:', error);
                }
            }
        }
    };

    const deleteItem = async (id) => {
        try {
            await api.delete(`/teachers/${id}`);
            const updatedItems = items.filter(item => item._id !== id);
            setItems(sortItems(updatedItems, sortOrder));
        } catch (error) {
            console.error('Error deleting teacher:', error);
        }
    };

    const checkForConflict = (name, id = null) => {
        return items.some(item => item.name === name && item._id !== id);
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleTrainingLevelChange = (e) => {
        setForm({ ...form, trainingLevel: e.target.value });
    };

    const editItem = (item) => {
        setForm({
            name: item.name,
            trainingLevel: item.trainingLevel?._id || '',
        });
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({ name: '', trainingLevel: '' });
        setEditMode(false);
        setCurrentId(null);
    };

    const sortItems = (items, order) => {
        return items.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : '';
            const nameB = b.name ? b.name.toLowerCase() : '';

            if (order === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
    };

    const toggleSortOrder = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setItems(sortItems([...items], newOrder));
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Teacher
            </Typography>
            <Can I="create" a="Teacher" ability={ability}>
                <form onSubmit={createOrUpdateItem}>
                    <TextField
                        label='Name'
                        name='name'
                        value={form.name}
                        onChange={handleInputChange}
                        margin='normal'
                        fullWidth
                        required
                    />
                    {(editMode || form.name) && (
                        <FormControl fullWidth margin='normal'>
                            {trainingLevels.length > 0 ? (
                                <>
                                    <InputLabel id="training-level-label">Training Level</InputLabel>
                                    <Select
                                        labelId="training-level-label"
                                        name="trainingLevel"
                                        value={form.trainingLevel}
                                        onChange={handleTrainingLevelChange}
                                        required
                                    >
                                        {trainingLevels.map((level) => (
                                            <MenuItem key={level._id} value={level._id}>
                                                {level.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            ) : (
                                <Typography variant="body1" color="error">
                                    No training levels available. Please{' '}
                                    <Link component={RouterLink} to="/training-level">
                                        create a training level
                                    </Link>{' '}
                                    before adding a teacher.
                                </Typography>
                            )}
                        </FormControl>
                    )}
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={!form.name || !form.trainingLevel} // Disable the button if no name or training level is selected
                    >
                        {editMode ? 'Update' : 'Save'}
                    </Button>
                    {editMode && (
                        <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                            Cancel
                        </Button>
                    )}
                </form>
            </Can>
            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '2rem' }}>
                <Typography variant='h5' gutterBottom>
                    List of Teachers
                </Typography>
                <Button variant="text" onClick={toggleSortOrder}>
                    Sort: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </Button>
            </Box>
            
            {items.map(item => (
                <ListItem key={item._id} divider>
                    <ListItemText primary={`${item.name} - ${item.trainingLevel?.name || 'No Level'}`} />
                    <Box display="flex" justifyContent="flex-end">
                        <Can I="update" a="Teacher" ability={ability}>
                            <IconButton edge="end" aria-label="edit" onClick={() => editItem(item)}>
                                <span>Edit</span>
                            </IconButton>
                        </Can>
                        <Can I="delete" a="Teacher" ability={ability}>
                            <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(item._id)}>
                                <span>Delete</span>
                            </IconButton>
                        </Can>
                    </Box>
                </ListItem>
            ))}
            {/* Conflict Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A teacher with the name "{form.name}" already exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Teacher;
