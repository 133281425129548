import React from 'react';
import Group from '../components/Group';

const GroupPage = () => {
    return (
        <div>
            <Group />
        </div>
    );
};

export default GroupPage;

