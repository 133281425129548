import React, { useState, useEffect } from 'react';
import {
    Container, Typography, TextField, Button, ListItem, ListItemText,
    Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { Can } from '@casl/react';  // Import Can from CASL
import { useAbility } from '../context/AbilityContext';  // Import the useAbility hook
import useAxios from '../hooks/useAxios';

const Location = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({
        name: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        email: '',
        phone: '',
        socialMediaLinks: {
            facebook: '',
            twitter: '',
            instagram: '',
            linkedin: ''
        }
    });
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [openDialog, setOpenDialog] = useState(false);
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get(`/locations`);
            const { data } = response.data;  // Destructure to get the data array from the response

            // Ensure the data is an array before sorting
            if (Array.isArray(data)) {
                const sortedData = sortItems(data, sortOrder);
                setItems(sortedData);
            } else {
                console.error('Expected an array but received:', data);
                setItems([]); // Handle non-array responses by resetting items
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const createItem = async (e) => {
        e.preventDefault();
        const conflict = checkForConflict(form.name);
        if (conflict) {
            setOpenDialog(true);
        } else {
            try {
                const response = await api.post(`/locations`, form);
                const newLocation = response.data.data; // Fully populated location returned from the backend
                const updatedItems = [...items, newLocation];
                setItems(sortItems(updatedItems, sortOrder));
                resetForm();
            } catch (error) {
                console.error('Error creating location:', error);
            }
        }
    };

    const updateItem = async (e) => {
        e.preventDefault();  // Prevent form submission from reloading the page
        try {
            const response = await api.put(`/locations/${currentId}`, form);
            const updatedLocation = response.data.data; // Get the updated location data from the response

            // Update the items in state
            const updatedItems = items.map(item => item._id === currentId ? updatedLocation : item);
            setItems(sortItems(updatedItems, sortOrder));

            resetForm();
        } catch (error) {
            console.error('Error updating location:', error);
        }
    };

    const deleteItem = async (id) => {
        try {
            await api.delete(`/locations/${id}`);
            const updatedItems = items.filter(item => item._id !== id);
            setItems(sortItems(updatedItems, sortOrder));
        } catch (error) {
            console.error('Error deleting location:', error);
        }
    };

    const checkForConflict = (name, id = null) => {
        return items.some(item => item.name === name && item._id !== id);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('socialMediaLinks.')) {
            const socialField = name.split('.')[1];
            setForm(prevForm => ({
                ...prevForm,
                socialMediaLinks: {
                    ...prevForm.socialMediaLinks,
                    [socialField]: value
                }
            }));
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const editItem = (item) => {
        setForm(item);
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({
            name: '',
            street: '',
            city: '',
            state: '',
            zipCode: '',
            email: '',
            phone: '',
            socialMediaLinks: {
                facebook: '',
                twitter: '',
                instagram: '',
                linkedin: ''
            }
        });
        setEditMode(false);
        setCurrentId(null);
    };

    const sortItems = (items, order) => {
        return items.sort((a, b) => {
            if (order === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
    };

    const toggleSortOrder = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setItems(sortItems([...items], newOrder));
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Location
            </Typography>

            {/* Conditionally render the form based on permissions */}
            <Can I="create" a="Location" ability={ability}>
                <form onSubmit={editMode ? updateItem : createItem}>  {/* Correctly calling either updateItem or createItem */}
                    <TextField
                        label='Name'
                        name='name'
                        value={form.name}
                        onChange={handleInputChange}
                        margin='normal'
                        fullWidth
                        required
                    />
                    {form.name && (
                        <>
                            <TextField
                                label='Street'
                                name='street'
                                value={form.street}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                            />
                            <TextField
                                label='City'
                                name='city'
                                value={form.city}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                            />
                            <TextField
                                label='State'
                                name='state'
                                value={form.state}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                            />
                            <TextField
                                label='Zip Code'
                                name='zipCode'
                                value={form.zipCode}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                            />
                            <TextField
                                label='Email'
                                name='email'
                                value={form.email}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                            />
                            <TextField
                                label='Phone'
                                name='phone'
                                value={form.phone}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                            />
                            <TextField
                                label='Facebook'
                                name='socialMediaLinks.facebook'
                                value={form.socialMediaLinks.facebook}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Twitter'
                                name='socialMediaLinks.twitter'
                                value={form.socialMediaLinks.twitter}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Instagram'
                                name='socialMediaLinks.instagram'
                                value={form.socialMediaLinks.instagram}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='LinkedIn'
                                name='socialMediaLinks.linkedin'
                                value={form.socialMediaLinks.linkedin}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                        </>
                    )}
                    <Button variant='contained' color='primary' type='submit'>
                        {editMode ? 'Update' : 'Save'}
                    </Button>
                    {editMode && (
                        <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                            Cancel
                        </Button>
                    )}
                </form>
            </Can>

            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '2rem' }}>
                <Typography variant='h5' gutterBottom>
                    List of Locations
                </Typography>
                <Button variant="text" onClick={toggleSortOrder}>
                    Sort: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </Button>
            </Box>

            {items.map(item => (
                <ListItem key={item._id} divider>
                    <ListItemText
                        primary={item.name}
                        secondary={`${item.street}, ${item.city}, ${item.state} ${item.zipCode} | Email: ${item.email} | Phone: ${item.phone}`}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Can I="update" a="Location" ability={ability}>
                            <span style={{ cursor: 'pointer', marginRight: '1rem' }} onClick={() => editItem(item)}>
                                Edit
                            </span>
                        </Can>
                        <Can I="delete" a="Location" ability={ability}>
                            <span style={{ cursor: 'pointer' }} onClick={() => deleteItem(item._id)}>
                                Delete
                            </span>
                        </Can>
                    </Box>
                </ListItem>
            ))}

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A location with the name "{form.name}" already exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Location;
