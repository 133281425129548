import { AbilityBuilder, createMongoAbility } from '@casl/ability';

// Define the abilities for a given user
export const defineAbilitiesFor = (user) => {
  const { can, cannot, rules } = new AbilityBuilder(createMongoAbility);

  if (user.role === 'admin') {
    can('manage', 'all'); // Admin can do everything
  } else if (user.role === 'editor') {
    // Editors have permissions to read and update entities
    can('read', 'User'); // Can read their own user details
    can('update', 'User', { id: user.id }); // Can update their own user details

    can('read', 'Company');
    can('update', 'Company', { userId: user.id });

    can('read', 'DefaultSlotSettings');
    can('update', 'DefaultSlotSettings', { userId: user.id });

    can('read', 'Event');
    can('update', 'Event', { userId: user.id });

    can('read', 'Group');
    can('update', 'Group', { userId: user.id });

    can('read', 'Lane');
    can('update', 'Lane', { userId: user.id });

    can('read', 'Location');
    can('update', 'Location', { userId: user.id });

    can('read', 'Schedule');
    can('update', 'Schedule', { userId: user.id });

    can('read', 'Slot');
    can('update', 'Slot', { userId: user.id });

    can('read', 'Student');
    can('update', 'Student', { userId: user.id });

    can('read', 'Teacher');
    can('update', 'Teacher', { userId: user.id });

    can('read', 'TeacherAvailability');
    can('update', 'TeacherAvailability', { userId: user.id });

    can('read', 'Ticket');
    can('update', 'Ticket', { userId: user.id });

    can('read', 'TicketType');
    can('update', 'TicketType', { userId: user.id });

    can('read', 'TrainingLevel');
    can('update', 'TrainingLevel', { userId: user.id });

    cannot('delete', 'all'); // Editors cannot delete any entities
  } else if (user.role === 'viewer') {
    // Viewers can only read (view) entities
    can('read', 'User', { id: user.id }); // Can only read their own user details

    can('read', 'Company');
    can('read', 'DefaultSlotSettings');
    can('read', 'Event');
    can('read', 'Group');
    can('read', 'Lane');
    can('read', 'Location');
    can('read', 'Schedule');
    can('read', 'Slot');
    can('read', 'Student');
    can('read', 'Teacher');
    can('read', 'TeacherAvailability');
    can('read', 'Ticket');
    can('read', 'TicketType');
    can('read', 'TrainingLevel');

    // Viewers have no permission to create, update, or delete any entities
    cannot('create', 'all');
    cannot('update', 'all');
    cannot('delete', 'all');
  } else {
    // Default permissions for any other roles
    can('read', 'User', { id: user.id }); // Can only read their own user details

    // Other roles have no other permissions by default
    cannot('manage', 'all');
  }

  // Return the ability based on the generated rules
  return createMongoAbility(rules);
};
