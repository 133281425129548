import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';  // Import the AuthContext
import { useAbility } from '../context/AbilityContext'; // Import the useAbility hook
import { Can } from '@casl/react';  // Import Can from CASL

const NavBar = () => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const { authState } = useContext(AuthContext);  // Get the authentication state
    const ability = useAbility();  // Get the user's abilities

    // console.log('Navbar abilities:', ability.rules);
    const handleMenuClick = (event) => {
        if (anchorEl && anchorEl.id === event.currentTarget.id) {
            setAnchorEl(null); // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget); // Open the menu
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getButtonStyle = (path) => {
        return {
            textDecoration: location.pathname === path ? 'underline' : 'none',
            color: 'inherit',
        };
    };

    const isMenuOpen = (menu) => anchorEl && anchorEl.id === menu;
  
    // console.log('Navbar abilities:', ability);
    // console.log('Navbar authState', authState.user);

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    PowderPlanner
                </Typography>
                {authState.isAuthenticated ? (  // Show these items only if the user is logged in
                    <Box sx={{ display: 'flex' }}>
                        <Can I="read" a="Schedule" ability={ability}>
                            <Button
                                sx={getButtonStyle('/schedule')}
                                component={Link}
                                to="/schedule"
                            >
                                Schedule
                            </Button>
                        </Can>

                        <Can I="read" a="Company" ability={ability}>
                            <Box id="company-menu">
                                <Button
                                    id="company-menu"
                                    onClick={handleMenuClick}
                                    sx={getButtonStyle('/company')}
                                >
                                    Company
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(isMenuOpen("company-menu"))}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem
                                        sx={getButtonStyle('/company')}
                                        component={Link}
                                        to="/company"
                                        onClick={handleMenuClose}
                                    >
                                        Company
                                    </MenuItem>
                                    <MenuItem
                                        sx={getButtonStyle('/location')}
                                        component={Link}
                                        to="/location"
                                        onClick={handleMenuClose}
                                    >
                                        Location
                                    </MenuItem>
                                    <MenuItem
                                        sx={getButtonStyle('/lane')}
                                        component={Link}
                                        to="/lane"
                                        onClick={handleMenuClose}
                                    >
                                        Piste
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Can>

                        <Can I="read" a="Teacher" ability={ability}>
                            <Box id="teacher-menu">
                                <Button
                                    id="teacher-menu"
                                    onClick={handleMenuClick}
                                    sx={getButtonStyle('/teacher')}
                                >
                                    Teacher
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(isMenuOpen("teacher-menu"))}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem
                                        sx={getButtonStyle('/teacher')}
                                        component={Link}
                                        to="/teacher"
                                        onClick={handleMenuClose}
                                    >
                                        Teacher
                                    </MenuItem>
                                    <MenuItem
                                        sx={getButtonStyle('/training-level')}
                                        component={Link}
                                        to="/training-level"
                                        onClick={handleMenuClose}
                                    >
                                        Training Level
                                    </MenuItem>
                                    <MenuItem
                                        sx={getButtonStyle('/teacher-availability')}
                                        component={Link}
                                        to="/teacher-availability"
                                        onClick={handleMenuClose}
                                    >
                                        Teacher Availability
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Can>

                        <Can I="read" a="Student" ability={ability}>
                            <Box id="student-menu">
                                <Button
                                    id="student-menu"
                                    onClick={handleMenuClick}
                                    sx={getButtonStyle('/student')}
                                >
                                    Student
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(isMenuOpen("student-menu"))}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem
                                        sx={getButtonStyle('/student')}
                                        component={Link}
                                        to="/student"
                                        onClick={handleMenuClose}
                                    >
                                        Student
                                    </MenuItem>
                                    <MenuItem
                                        sx={getButtonStyle('/group')}
                                        component={Link}
                                        to="/group"
                                        onClick={handleMenuClose}
                                    >
                                        Group
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Can>

                        <Can I="read" a="TicketType" ability={ability}>
                            <Button
                                sx={getButtonStyle('/ticket-type')}
                                component={Link}
                                to="/ticket-type"
                            >
                                Ticket Type
                            </Button>
                        </Can>

                        <Can I="read" a="DefaultSlotSettings" ability={ability}>
                            <Button
                                sx={getButtonStyle('/defaultslotsettings')}
                                component={Link}
                                to="/defaultslotsettings"
                            >
                                Default Slot Settings
                            </Button>
                        </Can>

                        <Can I="read" a="Event" ability={ability}>
                            <Button
                                sx={getButtonStyle('/event')}
                                component={Link}
                                to="/event"
                            >
                                Event log
                            </Button>
                        </Can>

                        <Button
                            sx={getButtonStyle('/account')}
                            component={Link}
                            to="/account"
                        >
                            Account
                        </Button>
                        <Button
                            sx={getButtonStyle('/logout')}
                            component={Link}
                            to="/logout"
                        >
                            Logout
                        </Button>
                    </Box>
                ) : (  // If not logged in, only show the Login and Register buttons
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            sx={getButtonStyle('/login')}
                            component={Link}
                            to="/login"
                        >
                            Login
                        </Button>
                        <Button
                            sx={getButtonStyle('/register')}
                            component={Link}
                            to="/register"
                        >
                            Register
                        </Button>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
