import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Can } from '@casl/react';  // Import Can from CASL
import useAxios from '../hooks/useAxios';
import { useAbility } from '../context/AbilityContext';  // Use your custom Ability context

const DefaultSlotSettings = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({});
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get(`/defaultslotsettings`);
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching default slot settings:', error);
        }
    };

    const createItem = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post(`/defaultslotsettings`, form);
            setItems([...items, response.data]);
            setForm({}); // Clear the form
        } catch (error) {
            console.error('Error creating default slot settings:', error);
        }
    };

    const updateItem = async (id) => {
        try {
            const response = await api.put(`/defaultslotsettings/${id}`, form);
            setItems(items.map(item => item._id === id ? response.data : item));
            setForm({}); // Clear the form
        } catch (error) {
            console.error('Error updating default slot settings:', error);
        }
    };

    const deleteItem = async (id) => {
        try {
            await api.delete(`/defaultslotsettings/${id}`);
            setItems(items.filter(item => item._id !== id));
        } catch (error) {
            console.error('Error deleting default slot settings:', error);
        }
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Default Slot Settings
            </Typography>

            {/* Conditionally render the form based on permissions */}
            <Can I="create" a="DefaultSlotSettings" ability={ability}>
                <form onSubmit={createItem}>
                    <TextField
                        label='Name'
                        name='name'
                        value={form.name || ''}
                        onChange={handleInputChange}
                        margin='normal'
                        fullWidth
                    />
                    <Button variant='contained' color='primary' type='submit'>
                        Save
                    </Button>
                </form>
            </Can>

            <Typography variant='h5' gutterBottom style={{ marginTop: '2rem' }}>
                List of Default Slot Settings
            </Typography>
            <List>
                {items.map(item => (
                    <ListItem key={item._id} divider>
                        <ListItemText primary={item.name} />
                        <Can I="update" a="DefaultSlotSettings" ability={ability}>
                            <IconButton edge='end' aria-label='edit' onClick={() => setForm(item)}>
                                <span>Edit</span>
                            </IconButton>
                        </Can>
                        <Can I="delete" a="DefaultSlotSettings" ability={ability}>
                            <IconButton edge='end' aria-label='delete' onClick={() => deleteItem(item._id)}>
                                <span>Delete</span>
                            </IconButton>
                        </Can>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default DefaultSlotSettings;
