import React from 'react';
import Logout from '../components/Logout';

const LogoutPage = () => {
    return (
        <div>
            <Logout />
        </div>
    );
};

export default LogoutPage;
