import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import useAxios from '../hooks/useAxios';

const AccountDetails = () => {
    const { authState } = useContext(AuthContext); // Get auth state from context
    const api = useAxios();
    const [userInfo, setUserInfo] = useState(authState.user || {}); // Always initialize as an object
    const [loading, setLoading] = useState(!authState.user); // Set loading if no user in context
    const [error, setError] = useState(null); // Track any errors

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (!authState.user) { // Only fetch if user info is not in context
                try {
                    const response = await api.get('/auth/me'); // Adjust the endpoint as needed
                    setUserInfo(response.data.user || {}); // Ensure fallback to empty object
                } catch (error) {
                    setError('Failed to fetch user info');
                    console.error('Failed to fetch user info:', error);
                } finally {
                    setLoading(false);
                }
            } else {
              
            }
        };

        fetchUserInfo();
    }, [api, authState.user]);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography>{error}</Typography>;
    }

    return (
        <Box>
            <Typography variant="h6">Account Details</Typography>
            <Typography>Email: {userInfo.email || 'N/A'}</Typography>
            <Typography>Username: {userInfo.username || 'N/A'}</Typography>
        </Box>
    );
};

export default AccountDetails;
