import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import useAxios from '../hooks/useAxios';

const TicketType = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({ name: '' });
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const api = useAxios();

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get(`/tickettype`);
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching ticket types:', error);
        }
    };

    const createOrUpdateItem = async (e) => {
        e.preventDefault();

        if (!form.name.trim()) {
            alert('Name cannot be empty');
            return;
        }

        try {
            if (editMode) {
                const response = await api.put(`/tickettype/${currentId}`, form);
                setItems(items.map(item => item._id === currentId ? response.data : item));
            } else {
                const response = await api.post(`/tickettype`, form);
                setItems([...items, response.data]);
            }
            resetForm();
        } catch (error) {
            console.error(`Error ${editMode ? 'updating' : 'creating'} ticket type:`, error);
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setOpenDialog(true);
    };

    const deleteItem = async () => {
        try {
            await api.delete(`/tickettype/${deleteId}`);
            setItems(items.filter(item => item._id !== deleteId));
            setOpenDialog(false);
        } catch (error) {
            console.error('Error deleting ticket type:', error);
        }
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const editItem = (item) => {
        setForm(item);
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({ name: '' });
        setEditMode(false);
        setCurrentId(null);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Ticket Type
            </Typography>
            <form onSubmit={createOrUpdateItem}>
                <TextField
                    label='Name'
                    name='name'
                    value={form.name || ''}
                    onChange={handleInputChange}
                    margin='normal'
                    fullWidth
                    required
                />
                <Button variant='contained' color='primary' type='submit'>
                    {editMode ? 'Update' : 'Save'}
                </Button>
                {editMode && (
                    <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                        Cancel
                    </Button>
                )}
            </form>
            <Typography variant='h5' gutterBottom style={{ marginTop: '2rem' }}>
                List of Ticket Types
            </Typography>
            <List>
                {items.map(item => (
                    <ListItem key={item._id} divider>
                        <ListItemText primary={item.name} />
                        <IconButton edge='end' aria-label='edit' onClick={() => editItem(item)}>
                            <Edit />
                        </IconButton>
                        <IconButton edge='end' aria-label='delete' onClick={() => confirmDelete(item._id)}>
                            <Delete />
                        </IconButton>
                    </ListItem>
                ))}
            </List>

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this ticket type? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteItem} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default TicketType;
