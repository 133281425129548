import React from 'react';
import TeacherAvailability from '../components/TeacherAvailability';

const TeacherAvailabilityPage = () => {
    return (
        <div>
            <TeacherAvailability />
        </div>
    );
};

export default TeacherAvailabilityPage;

