import React, { createContext, useContext, useMemo } from 'react';
import { defineAbilitiesFor } from '../abilities/defineAbilities';

export const AbilityContext = createContext();

export const AbilityProvider = ({ children, user }) => {
  // console.log('User role in AbilityProvider:', user.role); 

  const ability = useMemo(() => {
    const definedAbilities = defineAbilitiesFor(user || {});
    // console.log('Defined Abilities (rules):', definedAbilities.rules); // Log only the rules, more readable
    return definedAbilities;
  }, [user]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbility = () => useContext(AbilityContext);
