import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, ListItem, ListItemText,
  IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import useAxios from '../hooks/useAxios';
import { useAbility } from '../context/AbilityContext';  // Import useAbility
import { Can } from '@casl/react';  // Import Can from CASL

const Student = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        tel: '',
        email: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        level: ''
    });
    const [trainingLevels, setTrainingLevels] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [openDialog, setOpenDialog] = useState(false);
    const [error, setError] = useState(''); // State to handle validation error
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
        fetchTrainingLevels();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get(`/students`);
            const sortedData = sortItems(response.data.data, sortOrder); // Sort the data immediately after fetching
            setItems(Array.isArray(sortedData) ? sortedData : []);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const fetchTrainingLevels = async () => {
        try {
            const response = await api.get('/traininglevels');
            setTrainingLevels(response.data.data);
        } catch (error) {
            console.error('Error fetching training levels:', error);
        }
    };

    const createOrUpdateItem = async (e) => {
        e.preventDefault();

        if (!form.firstName.trim()) { // Validation: ensure first name is not empty
            setError('First name cannot be empty.');
            return;
        }

        if (!form.level) { // Validation: ensure level is selected
            setError('Please select a training level.');
            return;
        }

        setError(''); // Clear any previous errors

        const conflict = checkForConflict(form.firstName, form.lastName, form.zipCode, currentId);
        if (conflict) {
            setOpenDialog(true);
        } else {
            try {
                if (editMode) {
                    // Update existing student
                    const response = await api.put(`/students/${currentId}`, form);
                    setItems(items.map(item => item._id === currentId ? response.data.data : item));
                } else {
                    // Create new student
                    const response = await api.post(`/students`, form);
                    const newStudent = response.data.data;
                    const updatedItems = [...items, newStudent];
                    setItems(sortItems(updatedItems, sortOrder));
                }
                resetForm();
            } catch (error) {
                console.error('Error saving student:', error);
            }
        }
    };

    const deleteItem = async (id) => {
        try {
            await api.delete(`/students/${id}`);
            const updatedItems = items.filter(item => item._id !== id);
            setItems(sortItems(updatedItems, sortOrder));
        } catch (error) {
            console.error('Error deleting student:', error);
        }
    };

    const checkForConflict = (firstName, lastName, zipCode, id = null) => {
        return items.some(item => 
            item.firstName === firstName && 
            item.lastName === lastName && 
            item.zipCode === zipCode && 
            item._id !== id // Exclude the current item by ID
        );
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const editItem = (item) => {
        setForm({
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            tel: item.tel || '',
            email: item.email || '',
            address: item.address || '',
            zipCode: item.zipCode || '',
            city: item.city || '',
            country: item.country || '',
            level: item.level?._id || ''
        });
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({
            firstName: '',
            lastName: '',
            tel: '',
            email: '',
            address: '',
            zipCode: '',
            city: '',
            country: '',
            level: ''
        });
        setEditMode(false);
        setCurrentId(null);
        setError(''); // Clear error on reset
    };

    const sortItems = (items, order) => {
        return items.sort((a, b) => {
            const nameA = a.lastName ? a.lastName.toLowerCase() : ''; // Sort by last name
            const nameB = b.lastName ? b.lastName.toLowerCase() : '';

            if (order === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
    };

    const toggleSortOrder = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setItems(sortItems([...items], newOrder));
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Students
            </Typography>
            <Can I="create" a="Student" ability={ability}>
                <form onSubmit={createOrUpdateItem}>
                    <TextField
                        label='First Name'
                        name='firstName'
                        value={form.firstName || ''}
                        onChange={handleInputChange}
                        margin='normal'
                        fullWidth
                        required
                        error={!!error && !form.firstName}
                    />
                    {form.firstName && (
                        <>
                            <TextField
                                label='Last Name'
                                name='lastName'
                                value={form.lastName || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                                error={!!error && !form.lastName}
                                helperText={error}
                            />
                            <TextField
                                label='Telephone'
                                name='tel'
                                value={form.tel || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Email'
                                name='email'
                                value={form.email || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Address'
                                name='address'
                                value={form.address || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Zip Code'
                                name='zipCode'
                                value={form.zipCode || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                                required
                                error={!!error && !form.zipCode}
                            />
                            <TextField
                                label='City'
                                name='city'
                                value={form.city || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Country'
                                name='country'
                                value={form.country || ''}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <FormControl fullWidth margin='normal' required error={!!error && !form.level}>
                                <InputLabel id="level-label">Training Level</InputLabel>
                                <Select
                                    labelId="level-label"
                                    name="level"
                                    value={form.level}
                                    onChange={handleInputChange}
                                >
                                    {trainingLevels.map(level => (
                                        <MenuItem key={level._id} value={level._id}>
                                            {level.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!error && !form.level && <Typography color="error">{error}</Typography>}
                            </FormControl>
                        </>
                    )}
                    <Button variant='contained' color='primary' type='submit'>
                        {editMode ? 'Update' : 'Save'}
                    </Button>
                    {editMode && (
                        <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                            Cancel
                        </Button>
                    )}
                </form>
            </Can>
            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '2rem' }}>
                <Typography variant='h5' gutterBottom>
                    List of Students
                </Typography>
                <Button variant="text" onClick={toggleSortOrder}>
                    Sort: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </Button>
            </Box>
            {items.map(item => (
                <ListItem key={item._id} divider>
                    <ListItemText 
                        primary={`${item.firstName} ${item.lastName} - ${item.level?.name || 'No Level'}`} 
                        secondary={item.groups && item.groups.length > 0 ? `Group: ${item.groups.map(group => group.groupName).join(', ')}` : ''}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Can I="update" a="Student" ability={ability}>
                            <IconButton edge="end" aria-label="edit" onClick={() => editItem(item)}>
                                <span>Edit</span>
                            </IconButton>
                        </Can>
                        <Can I="delete" a="Student" ability={ability}>
                            <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(item._id)}>
                                <span>Delete</span>
                            </IconButton>
                        </Can>
                    </Box>
                </ListItem>
            ))}
            {/* Conflict Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A student with the name "{form.firstName} {form.lastName} and zip code "{form.zipCode}" already exists. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Student;
