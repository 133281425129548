import { useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const useAxios = () => {
    const { authState, refreshToken } = useContext(AuthContext);

    // Ensure that the AuthContext is available
    if (!authState || !refreshToken) {
        throw new Error('useAxios must be used within an AuthProvider that provides authState and refreshToken.');
    }

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL, // Fallback to backend URL if not provided
        withCredentials: true,  // Ensure credentials are sent with requests
    });
    if(!process.env.REACT_APP_API_BASE_URL){
      console.log('API Base URL:', process.env, process.env.REACT_APP_API_BASE_URL , 'should be: https://backend.dokku.noblestreet.eu/api');
    }
    useEffect(() => {
        // Interceptor to add Authorization header to requests
        const requestInterceptor = api.interceptors.request.use(
            (config) => {
                console.log('Starting Request:', config.url, config.method, config.headers);
                config.headers.Authorization = `Bearer ${authState.token}`;
                return config;
            },
            (error) => {
                console.error('Request Error:', error);
                return Promise.reject(error);
            }
        );

        // Interceptor to handle token refresh on 401 errors
        const responseInterceptor = api.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    await refreshToken();
                    originalRequest.headers.Authorization = `Bearer ${authState.token}`;
                    return api(originalRequest);
                }

                console.error('Response Error:', error.response || error);
                return Promise.reject(error);
            }
        );

        // Cleanup interceptors when the component unmounts
        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [authState, refreshToken]);

    return api;
};

export default useAxios;
