import React from 'react';
import Schedule from '../components/Schedule';

const SchedulePage = () => {
    return (
        <div>
            <Schedule />
        </div>
    );
};

export default SchedulePage;

