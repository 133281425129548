import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import useAxios from '../hooks/useAxios';
import { Can } from '@casl/react';  // Import Can from CASL
import { useAbility } from '../context/AbilityContext';  // Import useAbility

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const hours = Array.from({ length: 10 }, (_, i) => i + 8); // Hours from 8 AM to 5 PM

const Schedule = () => {
    const [lanes, setLanes] = useState([]); // Initialize lanes as an empty array
    const [selectedLane, setSelectedLane] = useState('');
    const [schedule, setSchedule] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentSlot, setCurrentSlot] = useState({ lane: '', teachers: [], students: [], slotDateTime: '' });
    const [teachers, setTeachers] = useState([]);
    const [students, setStudents] = useState([]);
    const [trainingLevels, setTrainingLevels] = useState([]); // New state for training levels
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchLanes();
        fetchTeachers();
        fetchStudents();
        fetchTrainingLevels(); // Fetch training levels
    }, []);

    useEffect(() => {
        if (selectedLane) {
            fetchSchedule(selectedLane);
        }
    }, [selectedLane]);

    const fetchLanes = async () => {
        try {
            const response = await api.get('/lanes');
            const lanesData = Array.isArray(response.data.data) ? response.data.data : []; // Ensure lanes is an array
            setLanes(lanesData);
            if (lanesData.length > 0) {
                setSelectedLane(lanesData[0]._id); // Set the first lane as selected by default
            }
        } catch (error) {
            console.error('Error fetching lanes:', error);
        }
    };

    const fetchTeachers = async () => {
        try {
            const response = await api.get('/teachers');
            setTeachers(Array.isArray(response.data.data) ? response.data.data : []);
        } catch (error) {
            console.error('Error fetching teachers:', error);
        }
    };

    const fetchStudents = async () => {
        try {
            const response = await api.get('/students');
            setStudents(Array.isArray(response.data.data) ? response.data.data : []);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const fetchTrainingLevels = async () => {
      try {
          const response = await api.get('/traininglevels');  // Ensure endpoint name is correct
          const sortedLevels = response.data.data // Adjust data access
              .filter(level => level.name)
              .sort((a, b) => a.name.localeCompare(b.name));
          setTrainingLevels(sortedLevels);
      } catch (error) {
          console.error('Error fetching training levels:', error);
      }
    };

    const fetchSchedule = async (laneId) => {
        try {
            const response = await api.get(`/schedules/lane/${laneId}`);
            setSchedule(Array.isArray(response.data) ? response.data : []); // Ensure schedule is an array
        } catch (error) {
            console.error('Error fetching schedule:', error);
        }
    };

    const handleSlotClick = (day, hour) => {
        const slotDateTime = new Date();  // Start with today's date
        slotDateTime.setDate(slotDateTime.getDate() + (day - slotDateTime.getDay() + 7) % 7);  // Set to the correct day
        slotDateTime.setHours(hour, 0, 0, 0);  // Set the correct hour

        const slot = schedule.find(slot =>
            new Date(slot.slotDateTime).getTime() === slotDateTime.getTime()
        );

        setCurrentSlot(slot || { lane: selectedLane, teachers: [], students: [], slotDateTime });
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentSlot({ lane: '', teachers: [], students: [], slotDateTime: '' });
    };

    const handleSaveSlot = async () => {
        try {
            if (currentSlot._id) {
                await api.put(`/schedules/${currentSlot._id}`, currentSlot);
            } else {
                await api.post('/schedules', currentSlot);
            }
            fetchSchedule(selectedLane);
            handleDialogClose();
        } catch (error) {
            console.error('Error saving schedule slot:', error);
        }
    };

    const handleSelectChange = (field, value) => {
        setCurrentSlot({ ...currentSlot, [field]: value });
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Lane Schedule
            </Typography>

            {/* Select Lane Dropdown */}
            <FormControl fullWidth margin="normal">
                <InputLabel id="lane-label">Select Lane</InputLabel>
                <Select
                    labelId="lane-label"
                    value={selectedLane || ''}
                    onChange={(e) => setSelectedLane(e.target.value)}
                >
                    {lanes.map(lane => (
                        <MenuItem key={lane._id} value={lane._id}>
                            {lane.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Schedule Grid */}
            <Box display="grid" gridTemplateColumns={`repeat(${daysOfWeek.length}, 1fr)`} gap={2}>
                {daysOfWeek.map((day, dayIndex) => (
                    <Box key={day} border="1px solid #ccc">
                        <Typography variant="h6" align="center">{day}</Typography>
                        {hours.map(hour => (
                            <Can I="update" a="Schedule" key={hour} ability={ability}>  {/* Check if the user can update the schedule */}
                                <Box
                                    padding="10px"
                                    border="1px solid #eee"
                                    backgroundColor={
                                        schedule.find(slot =>
                                            new Date(slot.slotDateTime).getDay() === dayIndex &&
                                            new Date(slot.slotDateTime).getHours() === hour
                                        )
                                            ? "lightgreen" : "lightgray"
                                    }
                                    onClick={() => handleSlotClick(dayIndex, hour)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {hour}:00
                                </Box>
                            </Can>
                        ))}
                    </Box>
                ))}
            </Box>

            {/* Slot Details Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Slot Details</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="teachers-label">Select Teachers</InputLabel>
                        <Select
                            labelId="teachers-label"
                            multiple
                            value={currentSlot?.teachers || []}
                            onChange={(e) => handleSelectChange('teachers', e.target.value)}
                        >
                            {teachers.map(teacher => (
                                <MenuItem key={teacher._id} value={teacher._id}>
                                    {teacher.name} ({teacher.level?.name || 'No Level'})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="students-label">Select Students</InputLabel>
                        <Select
                            labelId="students-label"
                            multiple
                            value={currentSlot?.students || []}
                            onChange={(e) => handleSelectChange('students', e.target.value)}
                        >
                            {students.map(student => (
                                <MenuItem key={student._id} value={student._id}>
                                    {student.firstName} {student.lastName} ({student.level?.name || 'No Level'})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="levels-label">Select Training Level</InputLabel>
                        <Select
                            labelId="levels-label"
                            value={currentSlot?.trainingLevel || ''}
                            onChange={(e) => handleSelectChange('trainingLevel', e.target.value)}
                        >
                            {trainingLevels.map(level => (
                                <MenuItem key={level._id} value={level._id}>
                                    {level.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="lane-dialog-label">Select Lane</InputLabel>
                        <Select
                            labelId="lane-dialog-label"
                            value={currentSlot?.lane || selectedLane}
                            onChange={(e) => handleSelectChange('lane', e.target.value)}
                        >
                            {lanes.map(lane => (
                                <MenuItem key={lane._id} value={lane._id}>
                                    {lane.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveSlot} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Schedule;
