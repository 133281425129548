import React from 'react';
import Company from '../components/Company';

const CompanyPage = () => {
    return (
        <div>
            <Company />
        </div>
    );
};

export default CompanyPage;

