import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import useAxios from '../hooks/useAxios';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setAuthState } = useContext(AuthContext);
    const api = useAxios();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await api.post('/auth/login', { email, password });
            
            // Check the response data and set the token and user details (including role)
            const { token, user } = res.data;

            // Set the token and user in the AuthContext
            setAuthState({
                token: token,
                isAuthenticated: true,
                user: user,  // Store the user details including the role
            });
            //console.log('Login user', user);
            
            // Store the token in localStorage for persistence
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user)); // Save the user data

            // Navigate to the home page
            navigate('/');
        } catch (err) {
            console.log('Login handleSubmit error', err.response?.data?.error);
            setError(err.response?.data?.error || 'Invalid credentials');
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            {error && (
                <Typography color="error" gutterBottom>
                    {error}
                </Typography>
            )}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    fullWidth
                    required
                />
                <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    fullWidth
                    required
                />
                <Box mt={2}>
                    <Button variant="contained" color="primary" type="submit">
                        Login
                    </Button>
                </Box>
            </form>
        </Container>
    );
};

export default Login;
