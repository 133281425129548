import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from './components/NavBar';
import { CssBaseline, Container } from '@mui/material';

import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';

import TeacherPage from './pages/TeacherPage';
import CompanyPage from './pages/CompanyPage';
import LocationPage from './pages/LocationPage';
import LanePage from './pages/LanePage';
import StudentPage from './pages/StudentPage';
import DefaultSlotSettingsPage from './pages/DefaultSlotSettingsPage';
import GroupPage from './pages/GroupPage';
import TeacherAvailabilityPage from './pages/TeacherAvailabilityPage';
import TicketTypePage from './pages/TicketTypePage';
import TrainingLevelPage from './pages/TrainingLevelPage';
import SchedulePage from './pages/SchedulePage';
import Logout from './pages/LogoutPage';
import AccountPage from './pages/AccountPage';

import { AuthContext } from './context/AuthContext';
import { AbilityProvider } from './context/AbilityContext'; // Import AbilityProvider

function Home() {
  return (
      <Container>
          <h1>Welcome to the Dashboard</h1>
          <p>Select an option from the navigation menu to get started.</p>
      </Container>
  );
}

function NotFound() {
  return (
      <Container>
          <h1>404 - Not Found</h1>
          <p>The page you are looking for does not exist.</p>
      </Container>
  );
}

function App() {
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthState({
        token,
        isAuthenticated: true,
        user: JSON.parse(localStorage.getItem('user')) || {}, // Ensure user data is stored and fetched
      });
    }
  }, [setAuthState]);

  
  return (
    <>
      <CssBaseline />
      <AbilityProvider user={authState.user || {}}>
        <NavBar />
        <Routes>
          <Route path="/" element={authState.isAuthenticated ? <Home /> : <Navigate to="/login" />} />
          <Route path="/teacher" element={authState.isAuthenticated ? <TeacherPage /> : <Navigate to="/login" />} />
          <Route path="/lane" element={authState.isAuthenticated ? <LanePage /> : <Navigate to="/login" />} />
          <Route path="/company" element={authState.isAuthenticated ? <CompanyPage /> : <Navigate to="/login" />} />
          <Route path="/schedule" element={authState.isAuthenticated ? <SchedulePage /> : <Navigate to="/login" />} />
          <Route path="/location" element={authState.isAuthenticated ? <LocationPage /> : <Navigate to="/login" />} />
          <Route path="/student" element={authState.isAuthenticated ? <StudentPage /> : <Navigate to="/login" />} />
          <Route path="/default-slot-settings" element={authState.isAuthenticated ? <DefaultSlotSettingsPage /> : <Navigate to="/login" />} />
          <Route path="/group" element={authState.isAuthenticated ? <GroupPage /> : <Navigate to="/login" />} />
          <Route path="/teacher-availability" element={authState.isAuthenticated ? <TeacherAvailabilityPage /> : <Navigate to="/login" />} />
          <Route path="/ticket-type" element={authState.isAuthenticated ? <TicketTypePage /> : <Navigate to="/login" />} />
          <Route path="/training-level" element={authState.isAuthenticated ? <TrainingLevelPage /> : <Navigate to="/login" />} />
          <Route path="/account" element={authState.isAuthenticated ? <AccountPage /> : <Navigate to="/login" />} />
          <Route path="/login" element={!authState.isAuthenticated ? <LoginPage /> : <Navigate to="/" />} />
          <Route path="/register" element={!authState.isAuthenticated ? <RegisterPage /> : <Navigate to="/" />} />
          <Route path="/logout" element={authState.isAuthenticated ? <Logout /> : <Navigate to="/login" />} /> {/* Add logout route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AbilityProvider>
    </>
  );
}

export default App;
