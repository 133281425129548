import React, { useState, useEffect } from 'react';
import {
    Container, Typography, TextField, Button, List, ListItem, ListItemText,
    IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import { Can } from '@casl/react';  // Import Can from CASL
import { useAbility } from '../context/AbilityContext';  // Import the useAbility hook
import useAxios from '../hooks/useAxios';

const Lane = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({ name: '', location: '' });
    const [locations, setLocations] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
    const [openDialog, setOpenDialog] = useState(false);
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
        fetchLocations();
    }, []);

    const fetchItems = async () => {
      try {
          const response = await api.get('/lanes');
          console.log('Lanes Response:', response.data); // Debugging: Log the response to ensure it's an array
  
          // Ensure the response is an array before sorting
          if (Array.isArray(response.data.data)) {
              const sortedData = sortItems(response.data.data, sortOrder);
              setItems(sortedData);
          } else {
              console.error('Expected an array but received:', response.data.data);
              setItems([]); // Handle non-array responses by resetting items
          }
      } catch (error) {
          console.error('Error fetching lanes:', error.response ? error.response.data : error.message);
      }
    };

    const fetchLocations = async () => {
      try {
          const response = await api.get('/locations');
          console.log('Locations Response:', response.data); // Debugging: Log the response to ensure it's an array
  
          // Ensure the response is an array before sorting
          if (Array.isArray(response.data.data)) {
              setLocations(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
          } else {
              console.error('Expected an array but received:', response.data.data);
              setLocations([]); // Handle non-array responses by resetting locations
          }
      } catch (error) {
          console.error('Error fetching locations:', error.response ? error.response.data : error.message);
      }
    };

    const createOrUpdateItem = async (e) => {
      e.preventDefault();
  
      if (!form.location) {
          alert('Please select a location before continuing.');
          return;
      }
  
      const requestBody = {
          name: form.name,
          location: form.location,
      };
  
      try {
          if (editMode) {
              // Update existing lane
              const response = await api.put(`/lanes/${currentId}`, requestBody);
              const updatedLane = response.data.data; // Ensure you're getting the updated lane object from the response
  
              // Update the items array with the updated lane
              const updatedItems = items.map(item =>
                  item._id === currentId ? updatedLane : item // Replace the edited item with the new data
              );
              setItems(sortItems(updatedItems, sortOrder)); // Sort after updating
              resetForm();
          } else {
              // Check for duplicate before creating new lane
              const conflict = checkForConflict(form.name);
              if (conflict) {
                  setOpenDialog(true);
              } else {
                  const response = await api.post('/lanes', requestBody);
                  const newLane = response.data.data; // Ensure you're getting the new lane object from the response
                  setItems(sortItems([...items, newLane], sortOrder)); // Sort after adding
                  resetForm();
              }
          }
      } catch (error) {
          console.error('Error creating/updating lane:', error.response ? error.response.data : error.message);
      }
    };
  

    const deleteItem = async (id) => {
        try {
            await api.delete(`/lanes/${id}`);
            const updatedItems = items.filter(item => item._id !== id);
            setItems(sortItems(updatedItems, sortOrder)); // Sort after deleting
        } catch (error) {
            console.error('Error deleting lane:', error.response ? error.response.data : error.message);
        }
    };

    const checkForConflict = (name, id = null) => {
        return items.some(item => item.name === name && item._id !== id);
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleLocationChange = (e) => {
        setForm({ ...form, location: e.target.value });
    };

    const editItem = (item) => {
        setForm({
            name: item.name,
            location: item.location?._id || '',
        });
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({ name: '', location: '' });
        setEditMode(false);
        setCurrentId(null);
    };

    const sortItems = (items, order) => {
        return items.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : '';
            const nameB = b.name ? b.name.toLowerCase() : '';

            if (order === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
    };

    const toggleSortOrder = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setItems(sortItems([...items], newOrder));
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Piste
            </Typography>

            {/* Conditionally render the form based on permissions */}
            <Can I="create" a="Lane" ability={ability}>
                <form onSubmit={createOrUpdateItem}>
                    <TextField
                        label='Name'
                        name='name'
                        value={form.name}
                        onChange={handleInputChange}
                        margin='normal'
                        fullWidth
                        required
                    />
                    <FormControl fullWidth margin='normal'>
                        {locations.length > 0 ? (
                            <>
                                <InputLabel id="location-label">Location</InputLabel>
                                <Select
                                    labelId="location-label"
                                    name="location"
                                    value={form.location}
                                    onChange={handleLocationChange}
                                    required
                                >
                                    {locations.map((location) => (
                                        <MenuItem key={location._id} value={location._id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <Typography variant="body1" color="error">
                                No locations available. Please create a location before adding a piste.
                            </Typography>
                        )}
                    </FormControl>
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={!form.name || !form.location}
                    >
                        {editMode ? 'Update' : 'Save'}
                    </Button>
                    {editMode && (
                        <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                            Cancel
                        </Button>
                    )}
                </form>
            </Can>

            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '2rem' }}>
                <Typography variant='h5' gutterBottom>
                    List of pistes
                </Typography>
                <Button variant="text" onClick={toggleSortOrder}>
                    Sort: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </Button>
            </Box>
            <List>
                {items.map(item => (
                    <ListItem key={item._id} divider>
                        <ListItemText primary={`${item.name} - ${item.location?.name || 'No Location'}`} />
                        <Box display="flex" justifyContent="flex-end">
                            <Can I="update" a="Lane" ability={ability}>
                                <IconButton edge="end" aria-label="edit" onClick={() => editItem(item)}>
                                    <span>Edit</span>
                                </IconButton>
                            </Can>
                            <Can I="delete" a="Lane" ability={ability}>
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(item._id)}>
                                    <span>Delete</span>
                                </IconButton>
                            </Can>
                        </Box>
                    </ListItem>
                
                ))}
            </List>

            {/* Conflict Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A piste with the name "{form.name}" already exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Lane;
