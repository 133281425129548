import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

const Logout = () => {
    const { setAuthState } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        setAuthState({
            token: null,
            isAuthenticated: false,
        });
        localStorage.removeItem('token'); // Remove token from local storage
        navigate('/login'); // Redirect to login page
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Logout
            </Typography>
            <Box mt={2}>
                <Button variant="contained" color="secondary" onClick={handleLogout}>
                    Logout
                </Button>
            </Box>
        </Container>
    );
};

export default Logout;
