import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, List, ListItem, ListItemText, IconButton, MenuItem, Select, FormControl, InputLabel, Box
} from '@mui/material';
import { Can } from '@casl/react';  // Import Can from CASL
import { useAbility } from '../context/AbilityContext';  // Use the AbilityContext
import useAxios from '../hooks/useAxios';

const Group = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({
        groupName: '',
        students: [],
        tel: '',
        email: '',
        address: '',
    });
    const [students, setStudents] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
        fetchStudents();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get('/groups');
            const sortedData = sortItems(response.data.data, sortOrder);  // Ensure correct data access
            setItems(Array.isArray(sortedData) ? sortedData : []);
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    };

    const fetchStudents = async () => {
        try {
            const response = await api.get('/students');
            const sortedStudents = response.data.data  // Adjust data access
                .sort((a, b) => {
                    const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
                    const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                });
            setStudents(sortedStudents);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const createOrUpdateItem = async (e) => {
        e.preventDefault();
        try {
            if (editMode) {
                await api.put(`/groups/${currentId}`, form);
            } else {
                await api.post('/groups', form);
            }
            fetchItems(); // Refresh the group list
            resetForm();
        } catch (error) {
            console.error('Error saving group:', error);
        }
    };

    const deleteItem = async (id) => {
        try {
            await api.delete(`/groups/${id}`);
            fetchItems(); // Refresh the group list after deletion
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleStudentChange = (e) => {
        setForm({ ...form, students: e.target.value });
    };

    const editItem = (item) => {
        setForm({
            groupName: item.groupName,
            students: item.students?.map(student => student._id) || [],
            tel: item.tel || '',
            email: item.email || '',
            address: item.address || '',
        });
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({
            groupName: '',
            students: [],
            tel: '',
            email: '',
            address: '',
        });
        setEditMode(false);
        setCurrentId(null);
    };

    const sortItems = (items, order) => {
        return items.sort((a, b) => {
            const nameA = a.groupName ? a.groupName.toLowerCase() : '';
            const nameB = b.groupName ? b.groupName.toLowerCase() : '';

            if (order === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
    };

    const toggleSortOrder = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setItems(sortItems([...items], newOrder));
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Group Management
            </Typography>

            {/* Conditionally render the form based on permissions */}
            <Can I="create" a="Group" ability={ability}>
                <form onSubmit={createOrUpdateItem}>
                    <TextField
                        label='Group Name'
                        name='groupName'
                        value={form.groupName}
                        onChange={handleInputChange}
                        margin='normal'
                        fullWidth
                        required
                    />
                    {form.groupName && (
                        <>
                            <TextField
                                label='Telephone'
                                name='tel'
                                value={form.tel}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Email'
                                name='email'
                                value={form.email}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <TextField
                                label='Address'
                                name='address'
                                value={form.address}
                                onChange={handleInputChange}
                                margin='normal'
                                fullWidth
                            />
                            <FormControl fullWidth margin='normal'>
                                <InputLabel id="student-label">Students</InputLabel>
                                <Select
                                    labelId="student-label"
                                    name="students"
                                    value={form.students}
                                    onChange={handleStudentChange}
                                    multiple
                                    required
                                >
                                    {students.map(student => (
                                        <MenuItem key={student._id} value={student._id}>
                                            {`${student.firstName} ${student.lastName}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={!form.groupName || form.students.length === 0} // Disable the button if no groupName or students are selected
                    >
                        {editMode ? 'Update' : 'Save'}
                    </Button>
                    {editMode && (
                        <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                            Cancel
                        </Button>
                    )}
                </form>
            </Can>

            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '2rem' }}>
                <Typography variant='h5' gutterBottom>
                    List of Groups
                </Typography>
                <Button variant="text" onClick={toggleSortOrder}>
                    Sort: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </Button>
            </Box>

            <List>
                {items.map(item => (
                    <ListItem key={item._id} divider>
                        <ListItemText primary={`${item.groupName} - ${item.students.map(student => `${student.firstName} ${student.lastName}`).join(', ') || 'No Students'}`} />
                        <Box display="flex" justifyContent="flex-end">
                            <Can I="update" a="Group" ability={ability}>
                                <IconButton edge="end" aria-label="edit" onClick={() => editItem(item)}>
                                    <span>Edit</span>
                                </IconButton>
                            </Can>
                            <Can I="delete" a="Group" ability={ability}>
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(item._id)}>
                                    <span>Delete</span>
                                </IconButton>
                            </Can>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default Group;
