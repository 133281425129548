import React from 'react';
import Student from '../components/Student';

const StudentPage = () => {
    return (
        <div>
            <Student />
        </div>
    );
};

export default StudentPage;

