import React, { useState, useEffect } from 'react';
import {
    Container, Typography, TextField, Button, ListItem, ListItemText,
    Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { Can } from '@casl/react';  // Import Can from CASL
import useAxios from '../hooks/useAxios';
import { useAbility } from '../context/AbilityContext';  // Use your custom Ability context

const Company = () => {
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({ name: '' });
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const api = useAxios();
    const ability = useAbility();  // Get the user's ability

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await api.get(`/company`);
            setItems(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching company:', error);
        }
    };

    const createItem = async (e) => {
        e.preventDefault();
        const conflict = checkForConflict(form.name);
        if (conflict) {
            setOpenDialog(true);
        } else {
            try {
                const response = await api.post(`/company`, form);
                setItems([response.data]); // Replace any existing company
                resetForm();
            } catch (error) {
                console.error('Error creating company:', error);
            }
        }
    };

    const updateItem = async (e) => {
        e.preventDefault();
        try {
            const response = await api.put(`/company/${currentId}`, form);
            setItems([response.data]); // Update the company
            resetForm();
        } catch (error) {
            console.error('Error updating company:', error);
        }
    };

    const deleteItem = async (id) => {
        try {
            await api.delete(`/company/${id}`);
            setItems([]); // Clear the list as there's only one company
            resetForm();
        } catch (error) {
            console.error('Error deleting company:', error);
        }
    };

    const checkForConflict = (name) => {
        return items.some(item => item.name === name);
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const editItem = (item) => {
        setForm(item);
        setEditMode(true);
        setCurrentId(item._id);
    };

    const resetForm = () => {
        setForm({ name: '' });
        setEditMode(false);
        setCurrentId(null);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Company
            </Typography>

            {/* Conditionally render the form if no company exists or if editing */}
            <Can I="create" a="Company" ability={ability}>
                {items.length === 0 || editMode ? (
                    <form onSubmit={editMode ? updateItem : createItem}>
                        <TextField
                            label='Name'
                            name='name'
                            value={form.name}
                            onChange={handleInputChange}
                            margin='normal'
                            fullWidth
                            required
                        />
                        <Button variant='contained' color='primary' type='submit'>
                            {editMode ? 'Update' : 'Save'}
                        </Button>
                        {editMode && (
                            <Button variant='outlined' color='secondary' onClick={resetForm} style={{ marginLeft: '1rem' }}>
                                Cancel
                            </Button>
                        )}
                    </form>
                ) : (
                    <Typography variant='h5' gutterBottom style={{ marginTop: items.length === 0 ? '2rem' : '0' }}>
                        Current Company
                    </Typography>
                )}
            </Can>

            {items.length > 0 && !editMode && items.map(item => (
                <ListItem key={item._id} divider>
                    <ListItemText primary={item.name} />
                    <Box display="flex" justifyContent="flex-end">
                        <Can I="update" a="Company" ability={ability}>
                            <span style={{ cursor: 'pointer', marginRight: '1rem' }} onClick={() => editItem(item)}>
                                Edit
                            </span>
                        </Can>
                        {/* <Can I="delete" a="Company" ability={ability}>
                            <span style={{ cursor: 'pointer' }} onClick={() => deleteItem(item._id)}>
                                Delete
                            </span>
                        </Can> */}
                    </Box>
                </ListItem>
            ))}

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A company with the name "{form.name}" already exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Company;
