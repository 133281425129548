import React from 'react';
import TicketType from '../components/TicketType';

const TicketTypePage = () => {
    return (
        <div>
            <TicketType />
        </div>
    );
};

export default TicketTypePage;

