import React from 'react';
import Location from '../components/Location';

const LocationPage = () => {
    return (
        <div>
            <Location />
        </div>
    );
};

export default LocationPage;

