import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import AccountDetails from '../components/AccountDetails';
import PasswordReset from '../components/PasswordReset';

const AccountPage = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Account Settings
            </Typography>
            <Box my={4}>
                <AccountDetails />
            </Box>
            <Box my={4}>
                <PasswordReset />
            </Box>
        </Container>
    );
};

export default AccountPage;
