import React from 'react';
import TrainingLevel from '../components/TrainingLevel';

const TrainingLevelPage = () => {
    return (
        <div>
            <TrainingLevel />
        </div>
    );
};

export default TrainingLevelPage;

