import React from 'react';
import Lane from '../components/Lane';

const LanePage = () => {
    return (
        <div>
            <Lane />
        </div>
    );
};

export default LanePage;

