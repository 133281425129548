import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: localStorage.getItem('token'), // Retrieve token from localStorage if available
        isAuthenticated: !!localStorage.getItem('token'), // Set initial auth status based on token
        user: null // Store the user details including role
    });

    useEffect(() => {
        if (authState.token) {
            // Set the Authorization header if the token is present
            axios.defaults.headers.common['Authorization'] = `Bearer ${authState.token}`;
            
            // Decode the token to extract user information and store it in authState
            const decodedToken = jwtDecode(authState.token); //jwtDecode(authState.token);
            // console.log('AuthProvider decodedToken', decodedToken);
            
            setAuthState(prevState => ({
                ...prevState,
                user: decodedToken, // Include user details such as role in the state
            }));
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }, [authState.token]);

    const refreshToken = async () => {
        try {
            const response = await axios.post('/auth/refresh-token', { token: authState.token });
            const newToken = response.data.token;

            const decodedToken = jwtDecode(newToken); //jwtDecode(newToken); // Decode the new token to get user details

            setAuthState({
                ...authState,
                token: newToken,
                isAuthenticated: true,
                user: decodedToken // Update user info in authState
            });

            localStorage.setItem('token', newToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        } catch (error) {
            console.error('Failed to refresh token', error);
            logout();
        }
    };

    const logout = () => {
        setAuthState({
            token: null,
            isAuthenticated: false,
            user: null // Clear user details on logout
        });
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
    };

    return (
        <AuthContext.Provider value={{ authState, setAuthState, refreshToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
