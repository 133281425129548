import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import useAxios from '../hooks/useAxios';

const PasswordReset = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const api = useAxios();

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const res = await api.post('/auth/reset-password', {
                currentPassword,
                newPassword
            });

            if (res.data.success) {
                setSuccess('Password successfully reset');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
        } catch (err) {
            setError(err.response?.data?.error || 'Password reset failed');
        }
    };

    return (
        <Box>
            <Typography variant="h6">Reset Password</Typography>
            {error && <Typography color="error">{error}</Typography>}
            {success && <Typography color="primary">{success}</Typography>}
            <form onSubmit={handlePasswordReset}>
                <TextField
                    label="Current Password"
                    type="password"
                    fullWidth
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    margin="normal"
                    required
                />
                <Box mt={2}>
                    <Button variant="contained" color="primary" type="submit">
                        Reset Password
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default PasswordReset;
