import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from './context/AuthContext'; // Import your AuthProvider

const container = document.getElementById('root');
const root = createRoot(container); // Create a root for rendering
root.render(
   <React.StrictMode>
      <AuthProvider>  {/* Wrap your application with AuthProvider */}
         <BrowserRouter>
            <CssBaseline />  {/* Provides baseline CSS for consistent styling across browsers */}
            <App />
         </BrowserRouter>
      </AuthProvider>
   </React.StrictMode>
);


// ReactDOM.render(
//   <React.StrictMode>
//     <Router>
//       <CssBaseline />  {/* Provides baseline CSS for consistent styling across browsers */}
//       <App />          {/* The main App component with routing and navigation */}
//     </Router>
//   </React.StrictMode>,
//   document.getElementById('root')
// );