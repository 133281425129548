import React from 'react';
import DefaultSlotSettings from '../components/DefaultSlotSettings';

const DefaultSlotSettingsPage = () => {
    return (
        <div>
            <DefaultSlotSettings />
        </div>
    );
};

export default DefaultSlotSettingsPage;

