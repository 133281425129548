import React, { useState, useEffect } from 'react';
import { Container, Typography, Select, MenuItem, Box, Button, Alert, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import useAxios from '../hooks/useAxios';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const TeacherAvailability = () => {
    const [teachers, setTeachers] = useState([]);
    const [lanes, setLanes] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(new Date());
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const api = useAxios();

    useEffect(() => {
        fetchTeachers();
        fetchLanes();
    }, []);

    const fetchTeachers = async () => {
        try {
            const response = await api.get('/teachers');
            console.log('Teachers:', response.data.data);
            setTeachers(response.data.data);
        } catch (error) {
            console.error('Error fetching teachers:', error);
            showSnackbar('Error fetching teachers', 'error');
        }
    };

    const fetchLanes = async () => {
        try {
            const response = await api.get('/lanes');
            console.log('Lanes:', response.data.data);
            setLanes(response.data.data); // Ensure lanes is an array
        } catch (error) {
            console.error('Error fetching lanes:', error);
            showSnackbar('Error fetching lanes', 'error');
        }
    };

    const handleTeacherSelect = (e) => {
        const teacherId = e.target.value;
        const teacher = teachers.find(teacher => teacher._id === teacherId);
        setSelectedTeacher(teacher);
    };

    const getWeekRange = (date) => {
        const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
        const endOfWeek = new Date(date.setDate(startOfWeek.getDate() + 6));
        return { startOfWeek, endOfWeek };
    };

    const previousWeek = () => {
        const prevWeek = new Date(currentWeek.setDate(currentWeek.getDate() - 7));
        setCurrentWeek(new Date(prevWeek));
    };

    const nextWeek = () => {
        const nextWeek = new Date(currentWeek.setDate(currentWeek.getDate() + 7));
        setCurrentWeek(new Date(nextWeek));
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Typography variant='h4' gutterBottom>
                Teacher Availability
            </Typography>

            {teachers.length > 0 ? (
                <Box>
                    <Select
                        value={selectedTeacher ? selectedTeacher._id : ''}
                        onChange={handleTeacherSelect}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>Select a teacher</MenuItem>
                        {teachers.map(teacher => (
                            <MenuItem key={teacher._id} value={teacher._id}>
                                {teacher.name}
                            </MenuItem>
                        ))}
                    </Select>

                    {selectedTeacher && (
                        <Box marginTop="2rem">
                            <Typography variant='h5'>{selectedTeacher.name}'s Availability</Typography>
                            <Box marginTop="1rem" display="flex" justifyContent="space-between" alignItems="center">
                                <Button variant="contained" onClick={previousWeek}>Previous Week</Button>
                                <Typography>
                                    {getWeekRange(currentWeek).startOfWeek.toDateString()} - {getWeekRange(currentWeek).endOfWeek.toDateString()}
                                </Typography>
                                <Button variant="contained" onClick={nextWeek}>Next Week</Button>
                            </Box>
                            {lanes.map(lane => (
                                <LanePanel 
                                    key={lane._id} 
                                    lane={lane} 
                                    teacherId={selectedTeacher._id} 
                                    currentWeek={currentWeek} 
                                    showSnackbar={showSnackbar} 
                                />
                            ))}
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    <Alert severity="info">
                        No teachers available. <Link to="/teacher">Add a teacher here</Link>.
                    </Alert>
                </Box>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Container>
    );
};

const LanePanel = ({ lane, teacherId, currentWeek, showSnackbar }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [availability, setAvailability] = useState([]); // Ensure this is an array
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [deselectedSlots, setDeselectedSlots] = useState([]);
    const api = useAxios();

    useEffect(() => {
        if (isExpanded) {
            fetchAvailability();
        }
    }, [isExpanded, currentWeek]);

    const fetchAvailability = async () => {
        try {
            const startOfWeek = new Date(currentWeek.setDate(currentWeek.getDate() - currentWeek.getDay()));
            const endOfWeek = new Date(currentWeek.setDate(startOfWeek.getDate() + 6));

            const response = await api.get(`/teacherAvailabilities?teacherId=${teacherId}&laneId=${lane._id}&startDate=${startOfWeek.toISOString()}&endDate=${endOfWeek.toISOString()}`);
            setAvailability(response.data.data); // Ensure availability is an array
            setSelectedSlots([]); // Clear selected slots when fetching availability
            setDeselectedSlots([]); // Clear deselected slots when fetching availability
        } catch (error) {
            console.error('Error fetching availability:', error.message);
            showSnackbar('Error fetching availability', 'error');
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleTimeSlotClick = (day, hour) => {
        const startTime = new Date(day);
        startTime.setHours(hour, 0, 0, 0);
        const endTime = new Date(startTime);
        endTime.setHours(hour + 1);

        const slot = {
            start: startTime,
            end: endTime,
        };

        const isAlreadySaved = availability.some(
            (avail) => new Date(avail.dateTimeRange.start).getTime() === slot.start.getTime() &&
                       new Date(avail.dateTimeRange.end).getTime() === slot.end.getTime()
        );

        if (isAlreadySaved) {
            const isDeselected = deselectedSlots.some(
                (s) => s.start.getTime() === slot.start.getTime() && s.end.getTime() === slot.end.getTime()
            );

            if (isDeselected) {
                setDeselectedSlots(deselectedSlots.filter(
                    (s) => s.start.getTime() !== slot.start.getTime() || s.end.getTime() !== slot.end.getTime()
                ));
            } else {
                setDeselectedSlots([...deselectedSlots, slot]);
            }
        } else {
            const isSelected = selectedSlots.some(
                (s) => s.start.getTime() === slot.start.getTime() && s.end.getTime() === slot.end.getTime()
            );

            if (isSelected) {
                setSelectedSlots(selectedSlots.filter(
                    (s) => s.start.getTime() !== slot.start.getTime() || s.end.getTime() !== slot.end.getTime()
                ));
            } else {
                setSelectedSlots([...selectedSlots, slot]);
            }
        }
    };

    const saveAvailability = async () => {
        try {
            if (deselectedSlots.length > 0) {
                const deletePromises = deselectedSlots.map(slot => {
                    const availabilityToRemove = availability.find(
                        (avail) => new Date(avail.dateTimeRange.start).getTime() === slot.start.getTime() &&
                                   new Date(avail.dateTimeRange.end).getTime() === slot.end.getTime()
                    );
                    if (availabilityToRemove) {
                        return api.delete(`/teacherAvailabilities/${availabilityToRemove._id}`);
                    }
                    return null;
                });

                await Promise.all(deletePromises.filter(Boolean));
                setAvailability(prevAvailability =>
                    prevAvailability.filter(avail =>
                        !deselectedSlots.some(slot =>
                            new Date(avail.dateTimeRange.start).getTime() === slot.start.getTime() &&
                            new Date(avail.dateTimeRange.end).getTime() === slot.end.getTime()
                        )
                    )
                );
            }

            if (selectedSlots.length > 0) {
                const newAvailability = selectedSlots.map(slot => ({
                    teacher: teacherId,
                    lane: lane._id,
                    dateTimeRange: {
                        start: slot.start,
                        end: slot.end,
                    },
                }));

                const response = await api.post('/teacherAvailabilities', newAvailability);
                setAvailability(prevAvailability => [...prevAvailability, ...response.data.data]);
                showSnackbar('Availability updated successfully');
            }

            setSelectedSlots([]);
            setDeselectedSlots([]);
        } catch (error) {
            console.error('Error saving availability:', error.message);
            showSnackbar('Error saving availability', 'error');
        }
    };

    const renderTimeSlots = (day) => {
        const slots = [];
        for (let hour = 8; hour <= 17; hour++) {
            const slotTime = new Date(day);
            slotTime.setHours(hour, 0, 0, 0);
            const isAvailable = availability.some(avail => {
                const start = new Date(avail.dateTimeRange.start);
                const end = new Date(avail.dateTimeRange.end);
                return slotTime >= start && slotTime < end;
            });
            const isSelected = selectedSlots.some(slot => slot.start.getTime() === slotTime.getTime());
            const isDeselected = deselectedSlots.some(slot => slot.start.getTime() === slotTime.getTime());

            slots.push(
                <Box
                    key={hour}
                    sx={{
                        padding: '10px',
                        backgroundColor: isDeselected ? 'salmon' : isSelected ? 'lightblue' : isAvailable ? 'lightgreen' : 'lightgray',
                        margin: '5px 0',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleTimeSlotClick(day, hour)}
                >
                    {`${hour}:00`} {isAvailable ? (isDeselected ? '- Deselected' : '- Available') : isSelected ? '- Selected' : ''}
                </Box>
            );
        }
        return slots;
    };

    return (
        <div className="panel">
            <div className="panel-header" onClick={toggleExpand}>
                <h3>{lane.name} - {lane.location?.name || 'No Location'}</h3>
                <span className={`toggle-button ${isExpanded ? 'expanded' : ''}`}>{'>'}</span>
            </div>
            {isExpanded && (
                <div className="panel-content">
                    <Box marginTop="1rem" display="flex" flexDirection="row" justifyContent="space-between">
                        {daysOfWeek.map((day, index) => {
                            const currentDate = new Date(currentWeek);
                            currentDate.setDate(currentWeek.getDate() - currentWeek.getDay() + index);
                            return (
                                <Box key={day} sx={{ flex: '1', margin: '5px' }}>
                                    <Typography variant="h6" align="center">{day}</Typography>
                                    {renderTimeSlots(currentDate)}
                                </Box>
                            );
                        })}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveAvailability}
                        disabled={selectedSlots.length === 0 && deselectedSlots.length === 0}
                        sx={{ marginTop: '2rem' }}
                    >
                        Save Selected Time Slots
                    </Button>
                </div>
            )}
        </div>
    );
};

export default TeacherAvailability;
